import React, {useEffect} from "react";
import journeyLogo from "../../assets/images/png/journeylogo.png";

// import footerLogo from "../../assets/images/svg/logo-mobile-primary.svg";
import footerLogo from "../../assets/images/png/wordlogoblue.png";

import linkedin from "../../assets/images/svg/linkedin.svg";
import twitter from "../../assets/images/svg/icon-twitter.svg";
import facebook from "../../assets/images/svg/facebook.svg";
import instagram from "../../assets/images/svg/instagram.svg";
import tiktok from "../../assets/images/svg/icon-tiktok.svg";
import youtube from "../../assets/images/svg/icon-youtube.svg";
import DancingArrow from "../DancingArrow/DancingArrow";
import PlayVideo from "../PlayVideo/PlayVideo";
import atlantisVideo from "../../assets/videos/Atlantis_Video.mp4";
import Button from "../UI_Elements/Button/Button";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { useCurrentYear } from "../../CustomHooks/useCurrentYear";
import { setActiveUrl } from "../../actions/NavigateUrls";
import { useDispatch, useSelector } from "react-redux";
import { checkDeviceType } from '../../actions/CheckDevice';

const Footer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const findCurrentYear = useCurrentYear();
  const activePage = useSelector((state) => state.navigateUrlsReducer.activeUrl);
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  const linkRoute = url => () => {
      dispatch(setActiveUrl(url));
      navigateToPage(url, navigate);
  };
  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch]);

  return (
    <div className="footer-wrapper">
     
      <div className="Footer-section ">
        <footer className="container">
          <div className="section-one" data-aos="fade-up" data-aos-delay="100">
            <div className="title">
              <h4>Explore Aquaventure World</h4>
              <p>
                Find the best package for yourself and get discounts when you
                book online.
              </p>
            </div>
            <div className="buttons">
              <div style={{ display: "inline-block", width: "261px" }}  >
                <Button
                  text="Visit Website"
                  buttonType="btn-backgrounded"
                  textColor="dark"
                  onClick={linkRoute('https://www.atlantis.com/dubai/atlantis-aquaventure')}
                >
                </Button>
              </div>
              <div>
                <img src={footerLogo} alt="" className="desktop-logo" />
              </div>
              <div style={{ display: "inline", width: "261px" }} data-aos="fade-up" data-aos-delay="400">
                <Button
                  text="Check Opening Time"
                  textColor="dark"
                  buttonType="btn-outlined"
                  onClick={linkRoute('https://www.atlantis.com/dubai/atlantis-aquaventure/aquaventure-waterpark')}
                >
                </Button>
              </div>
            </div>
          </div>
          <div className="section-two">
            <div className="grid md:grid-cols-4 grid-cols-1">
              <div className="footer-menu" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <h6 onClick={linkRoute("/attractions")}>Our Attractions</h6>
                </div>
                <ul>
                  <li>
                    <div onClick={linkRoute("/attractions/detail/5003")}>Dolphin Bay</div>
                  </li>
                  <li>
                    <div onClick={linkRoute("/attractions/detail/5004")}>Sea Lion Point</div>
                  </li>
                  <li>
                    <div onClick={linkRoute("/attractions/detail/5005")}>Shark Lagoon</div>
                  </li>
                </ul>
              </div>
              <div className="footer-menu" data-aos="fade-up" data-aos-delay="200">
                <h6 onClick={linkRoute("/experiences")}>Experiences</h6>
                <ul>
                  <li>
                    <div onClick={linkRoute("/experiences/details/5133")}>Ultimate Snorkel</div>
                  </li>
                  <li>
                    <div onClick={linkRoute("/experiences/details/5135")}>Aquatrek Xtreme</div>
                  </li>
                  <li>
                    <div onClick={linkRoute("/experiences/details/5056")}>Fish Tales Tour</div>
                  </li>
                  <li>
                    <div onClick={linkRoute("/experiences")} style={{ textDecoration: "underline" }}>View All</div>
                  </li>
                </ul>
              </div>
              <div className="footer-menu" data-aos="fade-up" data-aos-delay="300">
                <h6 onClick={linkRoute("/discover")}>Discover</h6>
                <ul>
                  <li>
                    <div onClick={linkRoute("/chambers")}>Chambers</div>
                  </li>
                  <li>
                    <div onClick={linkRoute("/all-animals")}>Animals</div>
                  </li>
                </ul>
              </div>
              <div className="footer-menu main-footer-links" data-aos="fade-up" data-aos-delay="400">
                <h6 onClick={linkRoute("/care-and-conservation")}>Care and Conservation</h6>
                <ul>
                  <li>
                    <div >
                      <h6 onClick={linkRoute("/faq")}>Frequently Asked Questions</h6>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="section-two-mb">
            <div className="footer-submenu">
              <ul>
              <li  data-aos="fade-up" data-aos-delay="50">
                  <div className="footer-submenu-item" onClick={linkRoute("/attractions")}>
                    <span>Our Attractions </span>
                    <DancingArrow delay={500} arrowAlingRight={true} />
                  </div>
                </li>
                <li  data-aos="fade-up" data-aos-delay="100"> 
                  <div className="footer-submenu-item" onClick={linkRoute("/experiences")}>
                    <span>Experiences</span>
                    <DancingArrow delay={400} arrowAlingRight={true} />
                  </div>
                </li>
                <li  data-aos="fade-up" data-aos-delay="150">
                  <div className="footer-submenu-item" onClick={linkRoute("/discover")}>
                    <span>Discover</span>
                    <DancingArrow delay={500} arrowAlingRight={true} />
                  </div>
                </li>
                <li  data-aos="fade-up" data-aos-delay="200">
                  <div className="footer-submenu-item" onClick={linkRoute("/care-and-conservation")}>
                    <span>Care & Conservation </span>
                    <DancingArrow delay={600} arrowAlingRight={true} />
                  </div>
                </li>
                <li  data-aos="fade-up" data-aos-delay="250">
                  <div className="footer-submenu-item" onClick={linkRoute("/faq")}>
                    <span>Frequently Asked Questions</span>
                    <DancingArrow delay={300} arrowAlingRight={true} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="section-three">
            <div data-aos="fade-up" data-aos-delay="300">
              <img src={footerLogo} alt="" className="mobile-logo" />
            </div>
            <div className="tag-social" data-aos="fade-up" data-aos-delay="400">
              <h4>Tag us on socials!</h4>
              <div className="social-icons ">
                <div onClick={linkRoute("https://www.linkedin.com/company/atlantis-resorts")}>
                  <img src={linkedin} alt="" />
                </div>
                <div onClick={linkRoute("https://twitter.com/Atlantis")}>
                  <img src={twitter} alt="" />
                </div>
                <div onClick={linkRoute("https://www.facebook.com/aquaventureworld")}>
                  <img src={facebook} alt="" />
                </div>
                <div onClick={linkRoute("https://www.instagram.com/aquaventureworld")}>
                  <img src={instagram} alt="" />
                </div>
                <div onClick={linkRoute("http://www.youtube.com/atlantisthepalmTV")}>
                  <img src={youtube} alt="" />
                </div>
                <div onClick={linkRoute("http://www.tiktok.com/@aquaventureworld")}>
                  <img src={tiktok} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="section-four"  data-aos="fade-up" data-aos-delay="500">
            <div className="bottom-footer" >
              {/* <div onClick={linkRoute("/")}><p>Resort Terms & Conditions</p></div> */}
              <div onClick={linkRoute("https://www.atlantis.com/dubai/terms-global")}><p>Web app Terms & Conditions</p></div>
              <div onClick={linkRoute("https://www.atlantis.com/dubai/privacy-policy")}> <p>Privacy Policy</p></div>
            </div>
            <span>© Aquaventure World. Copyright {findCurrentYear}.</span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
