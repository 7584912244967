import React, { useState } from 'react';
import MainTitleContent from '../../UI_Elements/MainTitleContent/MainTitleContent';
import Button from '../../UI_Elements/Button/Button';

import Dropdown from 'react-dropdown';

// import 'react-dropdown/style.css';

const PledgeForm = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mypledge, setMypledge] = useState('');
    const [errors, setErrors] = useState({});
    const [isOn, setIsOn] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedPledge, setSelectedPledge] = useState('');

    const options = [
        'Say “no thanks” to single-use plastics, and commit to making mindful choices for the sake of marine wildlife.',
        'Say “no thanks” to single-use plastics, and commit to making mindful choices for the sake of marine wildlife.',
        'Say “no thanks” to single-use plastics, and commit to making mindful choices for the sake of marine wildlife.'
      ];
      const defaultOption = options[0];

    const toggleSwitch = () => {
        setIsOn((prevState) => !prevState);
    };

    const validateForm = () => {
        const errors = {};

        if (!name.trim()) {
            errors.name = 'Name is required';
        }

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }

        if (!phone.trim()) {
            errors.phone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(phone)) {
            errors.phone = 'Phone number is invalid';
        }
        if (!phone.trim()) {
            errors.phone = 'Contact number is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            // Perform form submission or API call
            console.log('Form submitted:', { name, email, phone });
            // Reset form fields
            setName('');
            setEmail('');
            setPhone('');
            setErrors({});

            props.onSubmit();
            
        }
    };
    const handleCheckboxChange = () => {
        console.log('handleCheckboxChange press === ', isChecked);
        setIsChecked(!isChecked);
    };

   const _onSelect =(option) => {
        console.log('You selected ', option.label)
        setSelectedPledge(option.label);
      }

    return (
        <div className='pledgeFormContainer'>
            <form onSubmit={handleSubmit}>
                <MainTitleContent text='Take a Pledge' customClass="d-m-left" theme='themeLight' gapBetween="1px">
                </MainTitleContent>
                <div className='formSection'>
                    <div>
                        {/* <label htmlFor="name">Name:</label> */}
                        <input
                            className='field'
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Enter your name'
                        />
                        {errors.name && <span className='error'>{errors.name}</span>}
                    </div>
                    <div>
                        {/* <label htmlFor="email">Email:</label> */}
                        <input
                            className='field'
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email address'
                        />
                        {errors.email && <span className='error'>{errors.email}</span>}
                    </div>
                    <div>
                        {/* <label htmlFor="phone">Phone:</label> */}
                        <input
                            className='field'
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder='Contact No.'
                        />
                        {errors.phone && <span className='error'>{errors.phone}</span>}
                    </div>

                </div>
                <MainTitleContent text='What is your pledge?' customClass="d-m-left" theme='themeLight' gapBetween="1px">
                </MainTitleContent>
                <div className='formSection'>
                    <div className={`switchButton ${isOn ? 'on' : 'off'}`}>
                        <button
                            type="button"
                            className='switchButtonPick slideButtonText'
                            onClick={toggleSwitch}
                        >
                            Pick a pledge
                        </button>
                        <span className="slider slideButtonText">{isOn ? 'Write your own' : 'Pick a pledge'}</span>
                        <button
                            type="button"
                            className='switchButtonWrite slideButtonText'
                            onClick={toggleSwitch}
                        >
                            Write your own
                        </button>
                    </div>

                    <div>
                        {/* <label htmlFor="phone">Phone:</label> */}
                        {/* <input
                            className='field'
                            type="textarea"
                            id="mypledge"
                            value={mypledge}
                            onChange={(e) => setMypledge(e.target.value)}
                            placeholder='Write here...'
                        /> */}
                        {isOn?(
                        <textarea
                            rows={4}
                            className='textAreaField'
                            id="mypledge"
                            value={mypledge}
                            onChange={(e) => setMypledge(e.target.value)}
                            placeholder='Write here...'
                        />
                        ):(
                            <Dropdown className='pledgeDropdown' placeholderClassName='pledgePlaceholder' 
                            options={options} 
                            onChange={_onSelect} 
                            value={defaultOption} 
                            placeholder="Select an option"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />}
                             />
                        )}
                        {errors.mypledge && <span className='error'>{errors.mypledge}</span>}
                    </div>

                    <label className="checkIt">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className={isChecked ? "checked" : ""}
                        />
                        <p>I would like to receive a monthly news update from Atlantis Aquaventure World.</p>
                    </label>

                </div>
                <div className='modalFooter'>
                    <p className='note'>Your information will NOT be displayed publicly.</p>
                    <div className='submitAction'>
                        <Button text='Submit and Share' type="submit" buttonType='btn-backgrounded'></Button>
                        
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PledgeForm;