import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchingAttraction } from "../../actions/attraction";
import PageMainHeadingContent from '../../components/UI_Elements/PageMainHeadingContent/PageMainHeadingContent';
import TitleBlue from '../../components/UI_Elements/TitleBlue/TitleBlue';
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const Attractions = React.memo(() => {
  const navigate = useNavigate();
  let items = useSelector((state) => state.attraction.items);
  const metaTitle = useSelector(state => state.attraction.metaTitle);
  const metaImage = useSelector(state => state.attraction.metaImage);
  const metaDescription = useSelector(state => state.attraction.metaDescription);
  const activePage = useSelector((state) => state.navigateUrlsReducer.activeUrl);
  const dispatch = useDispatch();
const pageUrl= window.location.pathname;
  // useEffect(() => {
  //   if(pageUrl=='/attraction'){
  //   dispatch(setActiveUrl(pageUrl));
  //     console.log('pageUrl', pageUrl)
  //   }
  // }, [])
  useEffect(() => {
    dispatch(setActiveUrl('/attractions'));
  }, [])
  useEffect(() => {
  
    dispatch(fetchingAttraction());
  }, [dispatch]);

  return (
    <>
     <Helmet>
        <meta property="og:title" content={metaTitle}/>
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage}/>
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="attraction-container dynamicMinHeight mainHeadingTopSpace container ">
        <div style={{ marginLeft: "8px", marginRight: "8px" }}
             data-aos="fade-up"
             data-aos-delay="190">
          <PageMainHeadingContent heading={items?.section_attractions?.title} theme='themeLight' gapBetween="16px">
            <p>{items?.section_attractions?.description}</p>
          </PageMainHeadingContent>
        </div>
        <div className="gridWithBgImage">
          {items?.section_attractions?.attraction_list_page.map((data, key) => {
            return (
              <div key={key} className="gridItem activeCardContent"  data-aos="fade-up"  data-aos-delay={`${key}00`}
                onClick={() => data.ID != 4960 ? navigateToPage(`/attractions/detail/${data?.ID}`, navigate) :
                  navigateToPage(`/chambers`, navigate)
                }
              >
                <div className="gridImage">
                <img className="image" src={data?.image} alt="" />
                </div>
                <TitleBlue text={data?.title} customClass="d-m-center" ></TitleBlue>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
});

export default Attractions;
