import { FETCH_PAGES, FETCH_PAGES_ERROR } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingHome = () => async (dispatch) => {
  try {
    const response = await axiosCustom.get("pages/5070");
    const { data = false, status } = response;
    console.log("indexjs",response);
    if (status === 200) {
      if (data) {
        dispatch({
          type: FETCH_PAGES,
          items: data.acf,
          metaTitle: data?.page_meta?.meta_title,
          metaImage:data?.page_meta?.meta_image,
          metaDescription: data?.page_meta?.meta_description
        });
      }
    } else {
      dispatch({
        type: FETCH_PAGES_ERROR,
        error: "Error: Unable to fetch pages.",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PAGES_ERROR,
      error: "Error: " + error.message,
    });
  }
};
