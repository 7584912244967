import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkDeviceType } from '../../actions/CheckDevice';
import { fetchingChamberDetails } from '../../actions/AllChambers';
import ImageBannerWithContent from '../../components/UI_Elements/ImageBannerWithContent/ImageBannerWithContent';
import ImageBanner from '../../components/UI_Elements/ImageBanner/ImageBanner';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import MapwithStarfish from '../../components/MapwithStarFish/MapwithStarfish';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import BlueIconwithText from '../../components/BlueIconwithText/BlueIconwithText';
import Button from '../../components/UI_Elements/Button/Button';
import images from '../../assets/images/images';
import ImageBannerWithGradient from '../../components/UI_Elements/ImageBannerWithGradient/ImageBannerWithGradient';
import { useNavigate } from 'react-router-dom';
import { navigateToPage } from '../../CustomHooks/navigateToRoute';
import { setActiveUrl } from "../../actions/NavigateUrls";

const ChamberDetails = () => {
  const navigate = useNavigate();

  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  const chamberDetailsItem = useSelector(
    (state) => state.allChambers.itemsDetails
  );
  const dispatch = useDispatch();
  const params = useParams();
  const linkRoute = url => () => {
    dispatch(setActiveUrl(url));
    navigateToPage(url, navigate);
};
  useEffect(() => {
    dispatch(setActiveUrl('/chambers/details'));
  }, [])


  useEffect(() => {
    dispatch(checkDeviceType());
    dispatch(fetchingChamberDetails(params.chamberId));
  }, [dispatch, params.chamberId]);


  const renderBannerContent = () => {
    if (!chamberDetailsItem || !chamberDetailsItem.section_banner) {
      return null;
    }

    const {
      image,
      title,
      description
    } = chamberDetailsItem.section_banner;

    if (deviceType === 'desktop') {
      return (
        <ImageBannerWithContent
          customClass="dolphinBay"
          bannerImage={image}
          heading={title}
          subHeading={description?.rendered}
          imgAnimationType="zoom-out"
          imgAnimationDuration={100}
          descAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
          descAnimationDuration={100}
          titleAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
          titleAnimationDuration={100}
           textColor={'light'}
        />
      );
    }

    return (
      <div>
        <ImageBanner
          customClass="noContentBanner"
          customStyle={{ height: '341px', width: '100%', marginBottom: '25px' }}
          bannerImage={image}
          animationType={"fade-up"}
          animationDuration={100}
        />
        <div className="container">
          <MainTitleContent 
          text={title} 
          customClass="d-m-center" 
          theme="themeLight" 
          gapBetween="16px"
          cardAnimationType={"fade-up"}
          cardAnimationDuration={100}
          >
            <ParagraphDark para={description?.rendered} htmlPara={true} textColor={'light'} />
          </MainTitleContent>
        </div>
      </div>
    );
  };

  const renderMapContent = () => {
    if (!chamberDetailsItem || !chamberDetailsItem.section_map) {
      return null;
    }
    if (!chamberDetailsItem || !chamberDetailsItem.section_banner) {
      return null;
    }

    const { description: bannerDescription } = chamberDetailsItem.section_banner;



    const { title, description: mapDescription, explore_cta } = chamberDetailsItem.section_map;

        // Log the extracted values
    console.log('Section Banner:', bannerDescription);
    console.log('Section Map:', { title, mapDescription, explore_cta });

    const onclickbottomBtn = () => {
      navigateToPage('/chambers', navigate)
    }

    return (
      <ImageBannerWithGradient
        // Please pass 'noBgImage' as custom class to remove the backgrounf image and gradient.
        customClass="noBgImage DrakHoverButton"
        showDownloadLink={true}
        showMap={true}
        showButton={true}
        downloadBtnText='Download the map'
        bottomBtnText='Explore all Chambers'
        onclickbottomBtn={onclickbottomBtn}
      >
        <div className='headings'
        data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} 
        data-aos-delay={100}
        >
          <MainTitleContent
            text={title}
            customClass="d-left-m-center compactHeading"
            theme="themeWhite"
            gapBetween="16px"
          >
            <ParagraphDark 
            para={`
              ${bannerDescription?.rendered || bannerDescription}
              <br/>
              ${mapDescription}
            `} 
            htmlPara={true} 
            textColor={'light'} 
            />
            </MainTitleContent>
        </div>
      </ImageBannerWithGradient>

    );
  };

  const renderChamberDetails = () => {
    if (!chamberDetailsItem || !chamberDetailsItem.animal_categories) {
      return null;
    }

    const { animal_categories, more_info } = chamberDetailsItem;

    return (
      <div className="container">
        <div className="animals-chambers-wrap">
          <div className="animal-chambers-content-left">
            <MainTitleContent
              text="Animals In This Chamber"
              customClass="d-left-m-center"
              theme="themeDark"
              gapBetween="20px"
              cardAnimationType={deviceType == 'desktop'?"fade-up": "fade-up"} 
              cardAnimationDuration={100}
            />
            <div className="categories-extra-list">
              {animal_categories?.map((data, i) => (
                 <div 
                 key={images}
                 data-aos="fade-up"
                 data-aos-delay={`${i}00`}
                 className="blue-icon-card"
                 >
                <BlueIconwithText 
                image={data?.acf?.icon} 
                title={data?.name}
                 key={i} 
                 customClass="noActive" 
                 animationType={deviceType == 'desktop'?"fade-up": "fade-up"} 
                 animationDuration={`${i}00`}
                 />
                 </div>
              ))}
            </div>
          </div>
          <div className="animal-chambers-content-right">
            <MainTitleContent
              text={more_info?.description}
              customClass="d-left-m-center"
              theme="themeDark"
              gapBetween="20px"
              h5={true}
              cardAnimationType={deviceType == 'desktop'?"fade-up": "fade-up"}
              cardAnimationDuration={100}
            />
            <div style={{ width: '140px' }} 
            data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} 
            data-aos-delay={100}>
              <Button customClass="DrakHoverButton" text={more_info?.read_more_cta?.title} onClick={linkRoute("/experiences")} buttonType="btn-outlined"  textColor="dark"/>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-chamber-details dynamicMinHeight">
      {renderBannerContent()}
      {renderMapContent()}
      {renderChamberDetails()}
    </div>
  );
};

export default ChamberDetails;
