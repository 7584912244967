import fish_experience_image from "./experiences-demos/fish-experience.png";
import download_map_image from "./download_map.png";
import start_fish_image from "./starfish_blue_map.png";
import map_starfish from "./map-starfish.png";
import seven_sages_chamber_bg from "./seven-sages-chamber-bg.png";
import earthcheck_logo from "./earthcheck.png";
import marine_species from "./marine-species-img.png";
import association_logo from "./association-logo.png";
import lost_chamber_bg from './Lost_chamber_2.png';
import jelly_fish from './image-jellyfish.png';
import scan_fish from './scan.png';
import marine_speices_mb from './marine-species-mb.png';
import Activity_1 from "../images/png/activity-1.png";
import camera_focus from "./camera_focus.svg";
import zap_flash_off from "./zap_flash_off.svg";
import scan_fish_camera from "./scan-fish-img.png";
import chevron_right from "./chevron-right.svg";
import facebook_logo from "./facebook.svg";
import google_logo from "./google.svg";
import seahorse_logo from "./icon-seahorse.svg";
import all_animals_bg from "./allanimals-bg.png";
import oscillated_img from "./oscillated.png";
import shark_fish from "./shark-fish.svg";
import ambassador_lagon from "./ambassador-lagon.svg";
import chamber_logo from "./chamber-logo.svg";
import fishImage from "../images/fishImage.png";
import worldMap from "../images/worldMap.png";
import fireIcon from "./careConservation-demo/fireIcon.png";
import dottedLineWeb from "./careConservation/dots-web.png";
import dottedLineMob from "./careConservation/dots-mob.svg";
import family from "./careConservation/family.png";
import association_earth_check from "./careConservation/association-earthcheck.png";
import association_zoos from "./careConservation/association-zoos.png";
import location_logo from "./svg/MenuIcons/location.svg";
import aquventure_logo from "./svg/MenuIcons/aquventure.svg";
import discover_logo from "./svg/MenuIcons/discover.svg";
import faq_logo from "./svg/MenuIcons/faq.svg";
import care_logo from "./svg/MenuIcons/care.svg";
import iconFish from "./fishLibrary/icon_fish.svg";
import blueFish from "./fishLibrary/blue_fish.png";
import logo_Mobile from "./svg/logo-mobile.svg";
import conservation_bg from "./careConservation/video-wave.svg";
import fish_icon from "./fish-icon.svg";
import atlantisLogo from "./logos/atlantis_logo.png";
import stingGrey from "./homepage/sting-grey-big.png";
import marineSpeciesSmall from "./homepage/marine-species-small2.jpg";
import fishlength from './fishdetail/length.svg';
import fishWidth from './fishdetail/width.svg';
import fishLife from './fishdetail/life.svg';
import fishweight from './fishdetail/weight.svg';
import pageNotFound from './svg/pageNotFound.svg';

export default {
    fish_experience_image, download_map_image, start_fish_image, map_starfish, seven_sages_chamber_bg,
    earthcheck_logo, marine_species, association_logo, lost_chamber_bg, jelly_fish,
    scan_fish, marine_speices_mb, Activity_1, camera_focus, zap_flash_off, scan_fish_camera,
    chevron_right, facebook_logo, google_logo, seahorse_logo,
    fishImage, worldMap, fireIcon, dottedLineWeb, dottedLineMob, family,
    all_animals_bg, oscillated_img, shark_fish, ambassador_lagon, chamber_logo,association_earth_check, 
    association_zoos, location_logo, aquventure_logo,discover_logo, faq_logo, care_logo, blueFish, iconFish,
    logo_Mobile,conservation_bg, fish_icon, atlantisLogo, stingGrey, marineSpeciesSmall,
    fishlength,
    fishWidth,
    fishLife,
    fishweight,
    pageNotFound
}